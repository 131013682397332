<template>
  <div class="layout-padding" v-if="order">
    <PageTitle
      class="my-8"
      :title="content.order + ' #' + format(id)"
      :childOne="content.account"
      :childTwo="content.orderHistory"
    />
    <div class="my-5 grid grid-cols-12 gap-x-0 md:gap-x-10">
      <div class="col-span-12 lg:col-span-8 mb-12">
        <div
          class="
            border border-grayColor border-opacity-20
            p-4
            rounded
            grid grid-cols-1
            sm:grid-cols-2
            md:grid-cols-3
            lg:grid-cols-4
            gap-x-10
          "
        >
          <div class="col-span-1">
            <div class="font-bold">{{ content.container }}</div>
            {{
              order.lala_container
                ? content.lalaContainers
                : content.nonLalaContainers
            }}
          </div>
          <div class="col-span-1">
            <div class="font-bold mb-1">{{ content.containerSize }}</div>
            {{ order.size.title }}
          </div>
          <div class="col-span-1">
            <div class="font-bold mb-1">{{ content.wickType }}</div>
            {{ order.wick.title }}
          </div>
          <div class="col-span-1">
            <div class="font-bold mb-1">{{ content.scent }}</div>
            {{ order.scent.title }}
          </div>
          <div class="col-span-1">
            <div class="font-bold mb-1">{{ content.color }}</div>
            {{ order.color.title }}
          </div>
          <div class="col-span-1">
            <div class="font-bold mb-1">{{ content.colorPrice }}</div>
            {{ order.color.refill }}
          </div>
          <!-- <div class="col-span-1">
            {{ content.price }}
            {{ order.city ? parseFloat(order.total) + parseFloat(order.city.price) : order.total }}
          </div> -->
          <div class="col-span-1">
            <div class="font-bold mb-1">{{ content.price }}</div>
            {{ format(order.total) }}
          </div>
          <div class="col-span-1">
            <div class="font-bold mb-1">{{ content.date }}</div>
            {{ date }}
          </div>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-4">
        <OrderSummery
          :status="order.status"
          :date="order.date"
          :subTotal="order.total"
          :delivery="order.city?.price ?? 0"
          :total="total"
          :paid="order.paid"
          @pay="payLater"
        >
        </OrderSummery>
        <OrderShipping :order="order" />
      </div>
    </div>
  </div>
</template>

<script>
import OrderSummery from "/src/components/ui/Order/OrderSummery.vue";
import OrderShipping from "../../../components/ui/Order/OrderShipping.vue";
import { format } from "/src/assets/numberFormat";

export default {
  components: {
    OrderSummery,
    OrderShipping,
  },
  props: ["id"],
  inject: ["content"],
  async mounted() {
    await this.$store.dispatch("refill/refills");

    if (!this.$store.getters["refill/refill"](this.id)) {
      this.$router.replace("/account/orderHistory");
    }
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    order() {
      return this.$store.getters["refill/refill"](this.id);
      // let order = 'products'

      // return order;
    },
    date() {
      return `${this.order.date.split(",")[2]}/${
        this.order.date.split(",")[1]
      }/${this.order.date.split(",")[0]}`;
    },
    total() {
      return this.order.city
        ? parseFloat(this.order.total) + parseFloat(this.order.city.price)
        : this.order.total;
    },
  },
  methods: {
    format(number) {
      return format(number);
    },
    async payLater(method) {
      try {
        this.$store.dispatch("paylater/pay", {
          id: this.id,
          type: "refill",
          method: method,
        });
      } catch (error) {
        this.$store.dispatch("feedback/setFeedback", {
          type: "fail",
          title: this.content.fail,
          message: this.content.errorOccured,
        });
      }
    },
  },
};
</script>
